import React, { useState, useRef, useEffect } from "react";
import Style from "./DesktopNav.module.scss";
import { Link } from "react-router-dom";
import LoginSignup from "../LoginSignup";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Search from "../Search";
import { changeStatus } from "../../../../store/slices/serviceSlice";
import { useTranslation } from "react-i18next";

const DesktopNav = ({
  setActiveSearch,
  closeSearch,
  openSearch,
  searchTerm,
  searchHandleChange,
  searchData,
  setSearchTerm,
  menuData,
}) => {
  const { isActive } = useSelector((state) => state.menu);
  const pageService = useSelector((state) => state.pageService);
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const { t } = useTranslation(["common"]);
  const [dark, setDark] = useState(localStorage.getItem("lang"));
  const serviceRef = useRef(false);

  let location = useLocation();

  useEffect(() => {
    if (localStorage.i18nextLng == "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
  }, [localStorage.i18nextLng]);

  const clickLang = (lang) => {
    if (lang === "en") {
      setDark("en");
    } else {
      setDark("ar");
    }
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  let navMenu = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About Us",
      path: "/about-us",
    },
    {
      name: "Services",
      path: "",
    },
    {
      name: "Media",
      path: "/media",
    },
    {
      name: "Careers",
      path: "/careers",
    },
    {
      name: "Enquire",
      path: "/enquire",
    },
    {
      name: "Branches",
      path: "/our-branches",
    },
  ];

  const handleClickSubMenu = (slug) => {
    let newSlug = slug.split("/")[2];
    if (!pageService?.[newSlug]) {
      dispatch(changeStatus(newSlug));
    }
    serviceRef.current = true;
  };

  return (
    <>
      <nav className={Style.mainav}>
        {isActive ? (
          <ul>
            {menuData?.details?.length > 0 &&
              menuData?.details?.map((item, i) => {
                let activeMedia =
                  location.pathname.split("/")[1] == "media-detail" &&
                  item?.url == "/media";

                let activeCarrer =
                  location.pathname.split("/")[1] == "careers-detail" &&
                  item?.url == "/careers";
                let activeMenu = location.pathname == item?.url;
                return (
                  <>
                    {item?.title == t("servicesText") ? (
                      <>
                        <li
                          className={`${
                            location.pathname.includes(t("servicesText"))
                              ? Style.active
                              : ""
                          } `}
                          key={i}
                          onMouseEnter={() => setIsShown(true)}
                          onMouseLeave={() => setIsShown(false)}
                        >
                          <a
                            className={`${Style.service} ${
                              isShown && Style.dropdown
                            }`}
                          >
                            {item?.title}
                          </a>
                          {isShown && (
                            <div className={Style.dropdown_outer}>
                              <div
                                className={`${Style.menugrid} row  row-cols-lg-4`}
                              >
                                {item?.sub_menu?.map((menuItem, i) => {
                                  let activeSubMenu =
                                    location.pathname == menuItem?.url;
                                  return (
                                    <div
                                      className={`${
                                        activeSubMenu ? Style.active : ""
                                      } ${Style.menugrid__item} `}
                                      onClick={() =>
                                        handleClickSubMenu(menuItem?.url)
                                      }
                                      key={i}
                                    >
                                      <div className={Style.card_header}>
                                        <span className={Style.icon}>
                                          <img src={menuItem?.icon} alt="menu icon" />
                                        </span>
                                        <span
                                          className={`${
                                            Style.menugrid__subtitle
                                          } ${
                                            menuItem?.sub_menu?.some(
                                              (a) =>
                                                a?.url === location?.pathname
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <Link
                                            to={`${
                                              menuItem?.visiblity === "disable"
                                                ? ""
                                                : menuItem?.url
                                            }`}
                                          >
                                            {menuItem?.title}
                                          </Link>
                                        </span>
                                      </div>

                                      <ul className={Style.menugrid__list}>
                                        {menuItem?.sub_menu?.length > 0 &&
                                          menuItem?.sub_menu?.map(
                                            (data, index) => (
                                              <li
                                                className={`${Style.menugrid__item_inner}`}
                                                key={index}
                                              >
                                                <Link
                                                  to={`${
                                                    data?.visiblity ===
                                                    "disable"
                                                      ? ""
                                                      : data?.url
                                                  }`}
                                                  className={`${
                                                    Style.menugrid__item_inner
                                                  } ${
                                                    location?.pathname ===
                                                    data?.url
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  {data?.title}
                                                </Link>
                                                {data?.class === "new" && (
                                                  <span
                                                    className={Style.new_item}
                                                  >
                                                    {data?.class}
                                                  </span>
                                                )}
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </li>
                      </>
                    ) : (
                      <li
                        className={
                          activeMenu
                            ? Style.active
                            : activeMedia
                            ? Style.active
                            : activeCarrer
                            ? Style.active
                            : ""
                        }
                      >
                        <Link to={`${item?.url}`}>{item.title}</Link>
                      </li>
                    )}
                  </>
                );
              })}
            <li className={`only-desktop ${Style.lang}`}>
              <Link
                to="#"
                onClick={() => {
                  clickLang("en");
                }}
                className={dark === "en" ? Style.activeLang : ""}
              >
                English
              </Link>
              <span className={Style.vbar}></span>
              <Link
                to="#"
                onClick={() => {
                  clickLang("ar");
                }}
                className={dark === "ar" ? Style.activeLang : ""}
              >
                عربى
              </Link>
            </li>
          </ul>
        ) : (
          <ul className={Style.static_menu}>
            {navMenu?.map((item, i) => {
              let activeMenu = location.pathname == item?.path;
              return (
                <>
                  {item?.name == t("servicesText") ? (
                    <li
                      className={
                        location.pathname.includes(t("servicesText"))
                          ? Style.active
                          : ""
                      }
                      key={i}
                    >
                      <Link to={`${item?.path}`}>{item?.name}</Link>
                    </li>
                  ) : (
                    <li className={activeMenu ? Style.active : ""}>
                      <Link to={`${item?.path}`}>{item.name}</Link>
                    </li>
                  )}
                </>
              );
            })}
            <li className={`only-desktop ${Style.lang}`}>
              <Link
                to="#"
                onClick={() => {
                  clickLang("en");
                }}
                className={dark === "en" ? Style.activeLang : ""}
              >
                English
              </Link>
              <span className={Style.vbar}></span>
              <Link
                to="#"
                onClick={() => {
                  clickLang("ar");
                }}
                className={dark === "ar" ? Style.activeLang : ""}
              >
                عربى
              </Link>
            </li>
          </ul>
        )}
        <Search
          closeSearch={closeSearch}
          openSearch={openSearch}
          searchTerm={searchTerm}
          searchHandleChange={searchHandleChange}
          searchData={searchData}
          setSearchTerm={setSearchTerm}
          setActiveSearch={setActiveSearch}
        />
      </nav>
      <LoginSignup />
    </>
  );
};

export default DesktopNav;
