import React, { useState } from "react";
import Style from "./MediaDetail.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import RelatedTopics from "../RelatedTopics";
import { useSingleBlog } from "../../logic/useSingleBlog";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MediaDetail = () => {
  const { single } = useSingleBlog();
  const {t} = useTranslation(['common'])
  let dataSingle = single?.details?.single_post?.[0];

  let catID = single?.details?.single_post?.[0]?.categories_ids;

  const [isCopied, setIsCopied] = useState(false);
  const { isActive } = useSelector((state) => state.singleBlog);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  const isArabic = localStorage.getItem('lang') ==="ar"
  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className={isActive ? `container ${Style.loaded}` : "container"}>
        <div className={`${Style.smcontainer}`}>
          {dataSingle ? (
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>{t("HomeText")}</Link>{isArabic ? " / " : ""}
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/media"}>{t("MediaText")}</Link>
                </li>
                <li className={"breadcrumb-item"}>
                  <span>{dataSingle?.categories}</span>
                </li>
                <li className={"breadcrumb-item active"}>
                  <span>{dataSingle?.post_title}</span>
                </li>
              </ol>
            </nav>
          ) : (
            <div className={`${Style.postshimmer} ${Style.breadcrumb}`}>
              <span>{t("HomeText")}</span>
              <span>{t("MediaText")}</span>
              <span>{t("EventText")}</span>
              <span>{t("SignUpForText")}</span>
            </div>
          )}
        </div>
      </div>

      {dataSingle ? (
        <div className={Style.postdetail}>
          <div className="container">
            <div className={`${Style.smcontainer}`}>
              <div className={Style.smwrapper}>
                <div className={Style.title_detail}>
                  <div className={`smtitle ${Style.smtitle}`}>{dataSingle?.categories}</div>
                  <h2>{dataSingle?.post_title}</h2>
                  <div className={Style.postdate}>
                    <p>{dataSingle?.post_date_beautiful}</p>
                  </div>
                </div>
              </div>
              <div className={Style.postfigwrap}>
                {dataSingle?.post_thumbnail_url ? (
                  <figure className={Style.imgwrap}>
                    <img src={dataSingle?.post_thumbnail_url} alt="thumbnail" />
                  </figure>
                ) : (
                  ""
                )}
              </div>
              <div className={Style.smwrapper}>
                <div className={Style.postcnt}>{ReactHtmlParser(dataSingle?.post_content)}</div>
                <div className={Style.postdetail_share}>
                  <div className={Style.postdetail}>
                    <ul>
                      <li>{t("PostedByText")} {dataSingle?.post_author_name}</li>
                      <li>{t("CategoryText")}&nbsp;:&nbsp;{dataSingle?.categories}</li>
                    </ul>
                  </div>
                  <div className={Style.share}>
                    <span>{t("ShareText")}</span>
                    <ul>
                      <li>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                            window.location.href
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IcoMoon icon="facebook" size={17.97} />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://twitter.com/intent/tweet?url=${encodeURI(
                            window.location.href
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IcoMoon icon="twitter" size={19.65} />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.instagram.com/?url=${encodeURI(window.location.href)}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IcoMoon icon="insta" size={17.97} />
                        </a>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            handleCopyClick(
                              `${window.location.host}/media-detail/${dataSingle?.categories_ids}/${dataSingle?.ID}`
                            );
                          }}
                        >
                          <IcoMoon icon="attach" size={15.77} />
                        </Link>
                        {isCopied && <span className={Style.clip_to_board}>{t("CopiedText")}</span>}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className={Style.smcontainer}>
            <div className={Style.postshimmer}>
              <div className={Style.smwrapper}>
                <div className={Style.title_detail}>
                  <div className={`${Style.postshimmer_line} ${Style.postshimmer_smtitle}`}></div>
                  <div className={`${Style.postshimmer_line} ${Style.postshimmer_title}`}></div>
                  <div className={`${Style.postshimmer_line} ${Style.postshimmer_date}`}></div>
                </div>
              </div>
              <div className={`${Style.postshimmer_line} ${Style.postshimmer_img}`}></div>
              <div className={Style.smwrapper}>
                <div className={Style.postshimmer_cnt}>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                </div>
                <div className={Style.postshimmer_cnt}>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                  <span className={Style.postshimmer_line}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <RelatedTopics single={single} catID={catID} />
    </>
  );
};

export default MediaDetail;
