import React, { useEffect } from "react";
import Style from "./AboutBannerFold.module.scss";

import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutBannerFold = ({ aboutBannerData }) => {
  const { isActive } = useSelector((state) => state.pageAbout);

  return (
    <div className={`${Style.abtbnrfold} ${Style.loaded}`}>
      {aboutBannerData?.details?.["banner-featured-points"]?.status ===
        "true" && (
        <div className="container">
          <div className={Style.wrapper}>
            {aboutBannerData?.details?.["banner-featured-points"]?.[
              "banner-featured-points-items"
            ] &&
              aboutBannerData?.details?.["banner-featured-points"]?.[
                "banner-featured-points-items"
              ]?.length > 0 &&
              aboutBannerData?.details?.["banner-featured-points"]?.[
                "banner-featured-points-items"
              ]?.map((value, i) => {
                return (
                  <div key={i} className={"about-el"}>
                    <div
                      className={
                        isActive
                          ? `${Style.itemwrap} ${Style.vissible}`
                          : Style.itemwrap
                      }
                    >
                      <figure className={Style.itemimg}>
                        <img src={value?.icon?.url} alt="icon_img" />
                      </figure>
                      <div className={Style.itemdetail}>
                        <header>{ReactHtmlParser(value?.title)}</header>
                        <p>{value?.["sub-text"]}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutBannerFold;
