import CommonLayout from "../../components/Layouts/CommonLayout";
import CommonServiceInner from "../../components/CommonServiceInner";

const CommonServiceInnerPage = () => {
  
  return (
    <CommonLayout>
      <CommonServiceInner />
    </CommonLayout>
  );
};

export default CommonServiceInnerPage;
