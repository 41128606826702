import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Style from "./AllBranches.module.scss";
import AllBranch from "../Branches/AllBranch";
import { useBranches } from "../../logic/useBranches";
import { useNearestBranch } from "../../logic/useNearestBranch";
import { useParams } from "react-router-dom";
import BranchShimmer from "../Branches/AllBranch/BranchShimmer";
import { useTranslation } from "react-i18next";

const AllBranches = ({ currentLocationLoadMore, currentLocation }) => {
  const { postLocation } = useNearestBranch();
  const { branchSlug } = useParams();
  const [isLoadMoreHide, setIsLoadMoreHide] = useState(false);
  const { branchesData, fetchBranchDetails } = useBranches();
  const { t } = useTranslation(["common"]);
  useEffect(() => {
    fetchBranchDetails({
      posts_per_page: 6,
      page: 1,
      pid: branchSlug,
    });
  }, [branchSlug]);

  const [pageNumber, setPageNumber] = useState(1);

  const loadMoreclick = () => {
    // if (
    //   branchesData?.more_details?.current_page !=
    //   branchesData?.more_details?.total_pages
    // ) {
    //   const nextPage = branchesData?.more_details?.current_page + 1;
    //   setPageNumber(nextPage);
    // }

    setIsLoadMoreHide(true);
    fetchBranchDetails({
      posts_per_page: -1,
      page: 1,
      pid: branchSlug,
    });
  };

  useEffect(() => {
    if (pageNumber != 1) {
      if (currentLocationLoadMore) {
        postLocation({
          location: currentLocation,
          posts_per_page: 6,
          page: pageNumber,
        });
      } else {
        fetchBranchDetails({
          posts_per_page: 6,
          page: pageNumber,
        });
      }
    }
  }, [pageNumber]);

  return (
    <>
      <div className={Style.allbranches}>
        {branchesData ? (
          <>
            <div className={Style.branchlist}>
              {branchesData?.details?.map((value, i) => {
                return (
                  <div className={Style.fisrto} key={i}>
                    <AllBranch value={value} />
                  </div>
                );
              })}
            </div>
            <div className="text-center">
              {branchesData?.details?.length < 1 && <span>No Branches</span>}
            </div>
          </>
        ) : (
          <>
            <div className={Style.branchlist}>
              <div className={Style.fisrto}>
                <BranchShimmer />
              </div>
              <div className={Style.fisrto}>
                <BranchShimmer />
              </div>
              <div className={Style.fisrto}>
                <BranchShimmer />
              </div>
              <div className={Style.fisrto}>
                <BranchShimmer />
              </div>
              <div className={Style.fisrto}>
                <BranchShimmer />
              </div>
              <div className={Style.fisrto}>
                <BranchShimmer />
              </div>
            </div>
          </>
        )}

        {branchesData?.more_details?.current_page ===
        branchesData?.more_details?.total_pages ? (
          ""
        ) : (
          <div className="text-center">
            {!isLoadMoreHide && (
              <Button
                className={`btn btn-brdr ${Style.loadmore}`}
                onClick={() => {
                  loadMoreclick();
                }}
                id="loadmore"
              >
                <span>{t("loadAllBranchesText")}</span>
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AllBranches;
