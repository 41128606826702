import React, { useEffect } from "react";
import Style from "./CareerCultureAndValues.module.scss";
import Assets from "../Layouts/Assets";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const CareerCultureAndValues = ({ dropRef }) => {
  const { t } = useTranslation(["common"]);
  const { careersData, isActive } = useSelector((state) => state.careers);
  useEffect(() => {
    gsap.fromTo(
      `.${Style.imgwrap} img`,
      {
        scale: 1.1,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      0.2
    );
  }, []);
  return (
    <>
      {careersData && (
        <div
          className={
            isActive
              ? `${Style.culture_valuesec} ${Style.loaded}`
              : Style.culture_valuesec
          }
        >
          <div className="container">
            <div className={Style.outerwrap}>
              <div className={`row align-items-center ${Style.wrap}`}>
                <div className="col-md-5 col-lg-6">
                  <div className={Style.figwrap}>
                    <figure className={Style.imgwrap}>
                      <img
                        src={
                          careersData?.details?.page_sections?.[
                            "our-culture-and-values-field-set"
                          ]?.["our-culture-and-values-field-set-image"]?.url
                        }
                        alt="career_img "
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-md-7 col-lg-6">
                  <div className={Style.textwrap}>
                    <h2>
                      {
                        careersData?.details?.page_sections?.[
                          "our-culture-and-values-field-set"
                        ]?.["our-culture-and-values-field-set-main-text"]
                      }
                    </h2>
                    <p>
                      {ReactHtmlParser(
                        careersData?.details?.page_sections?.[
                          "our-culture-and-values-field-set"
                        ]?.["our-culture-and-values-field-set-main-description"]
                      )}
                    </p>
                    <Link
                      to="#"
                      className={`arw-link ${Style.arw_link}`}
                      onClick={() => {
                        dropRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                    >
                      <span>
                        <IcoMoon icon="arrow-right" size={12} />
                      </span>
                      {t("dropResumeText")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CareerCultureAndValues;
