import React, { useEffect } from "react";
import Style from "./RatesMobileView.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../../Layouts/IcoMooon";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RatesMobileView = ({ searchData }) => {
  const { t } = useTranslation(["common"]);
  const isArabic = localStorage.getItem("lang") === "ar";
  return (
    <div className={`d-md-none ${Style.tablesec}`}>
      <div className={`d-md-none ${Style.table}`}>
        <div className={Style.tablehead}>
          <div className="container">
            <div className={Style.currency}>{t("currencyTableHead1")}</div>
            <div className={Style.dd_dt}>{t("currencyTableHead2")}</div>
            <div className={Style.fc_buy}>{t("currencyTableHead3")}</div>
            <div className={Style.fc_cell}>{t("currencyTableHead4")}</div>
          </div>
        </div>
        <div className={Style.bodynote}>
          <div className="container">
            <ul className={Style.tablebody}>
              {searchData?.length > 0 &&
                searchData?.map((value, i) => {
                  return (
                    <li>
                      <div className={Style.currency} key={i}>
                        {isArabic ? value?.["title-ar"] : value?.["title-en"]}
                      </div>
                      <div className={Style.dd_dt}>{value?.dd_tt}</div>
                      <div className={Style.fc_buy}>{value?.fc_buy}</div>
                      <div className={Style.fc_cell}>{value?.fc_sell}</div>
                    </li>
                  );
                })}
            </ul>
            <div className={Style.tablenote}>
              <p>
                {t("exchangeRateSubtitle")}. {t("contactOurText")}{" "}
                <Link to={"/our-branches"}>
                  {" "}
                  {t("nearestBranchText")}&nbsp;{" "}
                </Link>
                {t("latestRateText")}.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.btnwrap}>
        <Link to={"/our-branches"} className={`btn btn-brdr ${Style.loadmore}`}>
          {t("loadMoreText")}
        </Link>
      </div>
    </div>
  );
};

export default RatesMobileView;
