import CurrencyFold from "../CurrencyFold";
import HomeServicesTab from "../HomeServicesTab";
import ExchangeRates from "../ExchangeRates";
import MoneyExchange from "../MoneyExchange";
import HomeBranchLocator from "../HomeBranchLocator";
import Testimonial from "../Testimonial";
import WhatsNew from "../WhatsNew";
import HomeAboutUs from "../HomeAboutUs";
import JoinUs from "../JoinUs";
import Loader from "../Loader";
import { useHomeBanner } from "../../logic/useHomeBanner";
import { useCurrencyRates } from "../../logic/useCurrencyRates";
import { useHomeOurService } from "../../logic/useHomeOurService";
import { useSelector } from "react-redux";
import { TitleComponent } from "../TitleComponent";
import { useHomeMobApp } from "../../logic/useHomeMobApp";
import { useHomeTestimonial } from "../../logic/useHomeTestimonial";
import HomeBannerSlider from "../HomeBannerSlider";
import { useEffect, useState } from "react";
import { useHomeBranchLocator } from "../../logic/useHomeBranchLocator";
import { useMedia } from "../../logic/useMedia";
import { useHomeAboutUs } from "../../logic/useHomeAboutUs";

const Home = () => {
  const { bannerData } = useHomeBanner();
  const { currencyRatesData } = useCurrencyRates();
  const { serviceData } = useHomeOurService();
  const { loaderClass } = useSelector((state) => state.service);
  const { mobileData } = useHomeMobApp();
  const { testimonialsData } = useHomeTestimonial();
  const { homeBranchData } = useHomeBranchLocator();
  const { mediaData, selectedMedia } = useMedia();
  const { aboutData } = useHomeAboutUs();

  if (serviceData && bannerData && currencyRatesData) {
    document.body.classList.remove("loader-visible");
  } else {
    document.body.classList.add("loader-visible");
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scroll = window.pageYOffset;
      scroll > 50
        ? document.body.classList.add("slim-header")
        : document.body.classList.remove("slim-header");
    });
  }, []);

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Sharaf Exchange",
    url: "https://sharafexchange.ae/",
    logo: "https://sharafexchange.ae/engine/wp-content/uploads/2024/12/licensed-by-white.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "600 54 0002",
      contactType: "customer service",
      contactOption: "TollFree",
      areaServed: "AE",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/SharafExchange/",
      "https://twitter.com/Sharaf_Exchange",
      "https://www.youtube.com/channel/UC3RrKmP9q5wmgVOs5OCMPXA",
      "https://www.linkedin.com/company/sharafexchange",
    ],
  };

  return (
    <main>
      {bannerData ? (
        <>
          <TitleComponent
            schema={schemaData}
            title={bannerData?.wpseo?.title}
            description={bannerData?.wpseo?.metadesc}
          />
          <HomeBannerSlider
            bannerData={bannerData}
            serviceData={serviceData}
            currencyRatesData={currencyRatesData}
          />
          <CurrencyFold />
          {currencyRatesData?.status === "true" && (
            <ExchangeRates currencyRatesData={currencyRatesData} />
          )}

          {serviceData?.details?.status === "true" && (
            <HomeServicesTab
              serviceData={serviceData?.details?.["our-services-items"]}
              title={serviceData?.details["service-featured-main-title"]}
              loaderClass={loaderClass}
            />
          )}

          {mobileData?.details?.status === "true" && (
            <MoneyExchange mobileData={mobileData} />
          )}
          {homeBranchData?.details?.status === "true" && (
            <HomeBranchLocator homeBranchData={homeBranchData} />
          )}
          {aboutData?.details?.status === "true" && (
            <HomeAboutUs aboutData={aboutData} />
          )}
          {testimonialsData?.details?.section_details?.status === "true" && (
            <Testimonial testimonialsData={testimonialsData} />
          )}
          {mediaData?.[selectedMedia]?.details?.status === "true" && (
            <WhatsNew mediaData={mediaData} selectedMedia={selectedMedia} />
          )}
          <JoinUs />
        </>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default Home;
