import React, { useEffect, useRef, useState } from "react";
import Style from "./CareerBanner.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger);

const CareerBanner = ({careersData,isActive}) => {

  const carrerbnrshape = useRef(),
    carrerbnrshape_two = useRef(),
    triggershape = useRef();
  useEffect(() => {
    if (careersData) {
    }
    gsap.to(carrerbnrshape.current, {
      x: 550,
      y: 1000,
      duration: 0.5,
      delay: 0.4,
      width: 50,
      height: 50,
      scrollTrigger: {
        scrub: 2,
      },
    });
    gsap.to(carrerbnrshape_two.current, {
      x: -200,
      y: -1000,
      rotate: 20,
      width: 300,
      height: 300,
      duration: 0.6,
      scrollTrigger: {
        scrub: 2,
      },
    });
  });
  return (
    <div
      className={
        isActive ? `${Style.carrerbnr} ${Style.loaded}` : Style.carrerbnr
      }
    >
      <span className={Style.circleshape} ref={carrerbnrshape}></span>
      <span className={Style.circleshape_two} ref={carrerbnrshape_two}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.textcol}>
            <div className={Style.textwrap}>
              <header className={Style.smtitle}>
                {careersData?.details?.post_title}
              </header>
              <h1>
                {ReactHtmlParser(
                  careersData?.details?.page_sections?.["banner-field-set"]?.[
                    "banner-field-set-main-text"
                  ]
                )}
              </h1>
              <p>
                {ReactHtmlParser(
                  careersData?.details?.page_sections?.["banner-field-set"]?.[
                    "banner-field-set-main-description"
                  ]
                )}
              </p>
            </div>
          </div>
          <div className={Style.imgcol}>
            <div className={Style.figwrap}>
              <figure className={Style.imgsec}>
                <img
                  src={
                    careersData?.details?.page_sections?.["banner-field-set"]?.[
                      "banner-field-set-image"
                    ]?.url
                  }
                  alt="career_img"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <span ref={triggershape}></span>
    </div>
  );
};

export default CareerBanner;
