import React, { useEffect } from "react";
import Style from "../HomeServicesTab.module.scss";
import IcoMoon from "../../Layouts/IcoMooon";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useNavigate, useLocation } from "react-router-dom";

const ServiceTabResults = ({ filteredIndex, loaderClass }) => {
  const { commonSlug } = useParams();

  let location = useLocation();
  const { isActive, status } = useSelector((state) => state.banner);

  const [ref1, inView1] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className={Style.resultwrapper}>
      {filteredIndex != null && (
        <div className={loaderClass ? `${Style.result_item} ${Style.loaded}` : Style.result_item}>
          <div ref={ref1} className={`${Style.figwrap} anim ${inView1 ? "vissible" : ""}`}>
            <figure className={Style.item_img}>
              <img src={filteredIndex?.["service-featured-image"]?.url} alt="service" />
            </figure>
          </div>
          <h3 ref={ref2} className={`anim-el ${Style.itemtitle} anim ${inView2 ? "vissible" : ""}`}>
            {location.pathname === "/services/value-added-services" ||
            window.location.pathname == "/services/" + commonSlug
              ? filteredIndex?.["service-featured-title"]
              : filteredIndex?.title}
          </h3>
          <div ref={ref3} className={`anim-el ${Style.shortcnt} anim ${inView3 ? "vissible" : ""}`}>
            <p>
              {location.pathname === "/services/value-added-services" ||
              window.location.pathname == "/services/" + commonSlug
                ? filteredIndex?.["service-featured-description"]
                : filteredIndex?.description}
            </p>
          </div>

          {window.location.pathname === "/services/value-added-services" ||
          window.location.pathname == "/services/" + commonSlug ? (
            ""
          ) : filteredIndex?.["service-link"]?.url?.indexOf("://") == -1 ? (
            <Link
              to={`${filteredIndex?.["service-link"]?.url}`}
              className={`arw-link ${Style.all_btn}`}
              onClick={() =>
                localStorage.setItem("localPath", filteredIndex?.["service-link"]?.url)
              }
            >
              <span>
                <IcoMoon icon="arrow-right" size={12} />
              </span>
              {filteredIndex?.["service-link"]?.text}
            </Link>
          ) : (
            <a
              href={filteredIndex?.["service-link"]?.url}
              className={`arw-link ${Style.all_btn}`}
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <IcoMoon icon="arrow-right" size={12} />
              </span>
              {filteredIndex?.["service-link"]?.text}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceTabResults;
