import React from "react";
import Style from "../Footer.module.scss";
import SocialMedias from "../../../SocialMedias";
import CompanyMenu from "./CompanyMenu";
import ServicesMenu from "./ServicesMenu";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import FooterMobileMenu from "./FooterMobileMenu";
import { useDimensions } from "../../../../logic/Dimensions";
import Assets from "../../Assets";
import { useTranslation } from "react-i18next";

const FooterMenus = () => {
  const { footerData } = useSelector((state) => state.footer);
  const { width } = useDimensions();
  const { t } = useTranslation(["common"]);
  return (
    <div className={Style.ftr_menu_addr}>
      {width < 768 ? (
        <div className={Style.cols}>
          <div className={Style.mobileview}>
            <FooterMobileMenu />
          </div>
        </div>
      ) : (
        <>
          <div className={`${Style.cols} ${Style.companycol}`}>
            <CompanyMenu />
          </div>
          <div className={`${Style.cols} ${Style.servicecol}`}>
            <ServicesMenu />
          </div>
        </>
      )}

      <div className={`${Style.cols} ${Style.officecol}`}>
        <div className={Style.reachout}>
          {footerData && (
            <div className={Style.titlesec}>{t("headOfficeText")}</div>
          )}
          <p>
            {ReactHtmlParser(
              footerData?.details?.footer_more_details?.wac_footer_address
            )}
          </p>

          <div className={Style.logowrap}>
            <img src={Assets.logo_white} alt=" sharaf logo" />
            <p className={Style.logoText}>
              {ReactHtmlParser(
                footerData?.details?.footer_more_details?.logo_title
              )}
            </p>

            <SocialMedias />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterMenus;
