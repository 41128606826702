import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Style from "./Media.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useBlog } from "../../logic/useBlog";
import { getBlog } from "../../store/slices/blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useBlogCategory } from "../../logic/useBlogCategory";
import ReactHtmlParser from "react-html-parser";
import ModalGallery from "./ModalGallery";

import MediaShimmer from "./MediaShimmer";
import ProgressLoader from "../ProgressLoader";

import { useMedia } from "../../logic/useMedia";
import {
  getMediaData,
  handleSelectedMediaCategory,
  loadMore,
} from "../../store/slices/mediaSlice";
import { useTranslation } from "react-i18next";
import { values } from "lodash";
import moment from "moment";
import { TitleComponent } from "../TitleComponent";

const Media = () => {
  const { t } = useTranslation(["common"]);
  const [pageNumber, setPageNumber] = useState(1);
  const [galleryModal, setGalleryModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { mediaCategoryData, mediaData, selectedMedia, isActive, setIsActive } =
    useMedia();

  let catID = location?.search?.split("?")[1];
  let isArabic = localStorage.getItem("lang") === "ar";
  if(isArabic){
    moment.locale('ar')
  }else{
    moment.locale('en')
  }
  const handleClick = (value) => {
    dispatch(handleSelectedMediaCategory(value));
    setIsActive(value);
    if (mediaData[value] == undefined) {
      dispatch(getMediaData({ catSlug: value, perPage: 8, page: 1 }));
    }
  };

  useEffect(() => {
    if (catID) {
      dispatch(handleSelectedMediaCategory(catID));
      setIsActive(Number(catID));
      if (mediaData[catID] == undefined) {
        dispatch(getMediaData({ catSlug: catID, perPage: 8, page: 1 }));
      }
    }
  }, []);

  const loadMoreclick = () => {
    if (
      mediaData[selectedMedia]?.details?.more_details?.current_page <
      mediaData[selectedMedia]?.details?.more_details?.total_pages
    ) {
      const nextPage =
        mediaData[selectedMedia]?.details?.more_details?.current_page + 1;
      setPageNumber(nextPage);
      dispatch(
        loadMore({ catSlug: selectedMedia, perPage: 8, page: nextPage })
      );
    }
  };


  return (
    <>
      {mediaCategoryData?.details ? "" : <ProgressLoader />}
      <TitleComponent
        title={mediaCategoryData?.wpseo?.title}
        description={mediaCategoryData?.wpseo?.metadesc}
      />
      <div className={`${Style.mediasection} ${Style.loaded}`}>
        <div className="container">
          <div className={Style.title_n_tab}>
            <div className={Style.titlesec}>
              {mediaCategoryData && <h2>{t("recentPostText")}</h2>}
            </div>
            <div className={Style.tabswrap}>
              <ul>
                {mediaCategoryData?.details &&
                  mediaCategoryData?.details?.length > 0 &&
                  mediaCategoryData?.details?.map((value, i) => {
                    return (
                      <>
                        <li
                          key={i}
                          onClick={() => {
                            handleClick(value?.term_id);
                          }}
                        >
                          <Button
                            className={
                              isActive === value?.term_id
                                ? `btn btn-brdr ${Style.tabtn} ${Style.active}`
                                : `btn btn-brdr ${Style.tabtn}`
                            }
                          >
                            <span>{value?.name}</span>
                          </Button>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
          {/* {console.log("media data==", mediaData)} */}
          <div className={Style.mediaslist}>
            {mediaData?.[selectedMedia]?.details?.blog_list &&
            mediaData?.[selectedMedia]?.details?.blog_list?.length > 0 ? (
              mediaData?.[selectedMedia]?.details?.blog_list?.map(
                (value, i) => {
                  if (value?.categories_ids == isActive) {
                    return (
                      <div key={i}>
                        {value?.categories_ids === t("galleryText") ? (
                          <div
                            onClick={() => {
                              setGalleryModal(true);
                              document
                                .getElementById("changeLag")
                                .classList.toggle("modal-open");

                              setCurrentIndex(i);
                            }}
                            className={`${Style.newitem} ${Style.gallery}`}
                          >
                            <figure className={Style.postimg}>
                              <img
                                src={value?.post_small_thumbnail_url}
                                alt="thumbnail"
                              />
                            </figure>
                          </div>
                        ) : (
                          <Link
                            to={`/media-detail/${value?.categories_ids}/${value?.ID}`}
                            className={Style.newitem}
                          >
                            {value?.post_thumbnail_url && (
                              <figure className={Style.postimg}>
                                <img
                                  src={value?.post_small_thumbnail_url}
                                  alt="thumbnail"
                                />
                              </figure>
                            )}
                            <div className={Style.details}>
                              <div className={Style.postype}>
                                {value?.categories}
                              </div>
                              <header className={Style.postitle}>
                                {value?.post_title}
                              </header>
                              {!value?.post_thumbnail_url && (
                                <div className={Style.cntsec}>
                                  <p>{ReactHtmlParser(value?.post_content)}</p>
                                </div>
                              )}

                              <div className={Style.postdate}>
                                {/* {moment( value?.post_date_beautiful ?? "13 Sep 5000").format('LL')} */}
                                {value?.post_date_beautiful}
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    );
                  }
                }
              )
            ) : (
              <MediaShimmer />
            )}
          </div>
          {galleryModal && (
            <ModalGallery
              data={mediaData?.[selectedMedia]?.details?.blog_list}
              galleryModal={galleryModal}
              setGalleryModal={setGalleryModal}
              index={currentIndex}
            />
          )}

          {mediaData[selectedMedia]?.details?.more_details?.current_page <
          mediaData[selectedMedia]?.details?.more_details?.total_pages ? (
            <div className={Style.btnwrap}>
              <Button
                className={`btn btn-brdr ${Style.loadmore}`}
                onClick={() => loadMoreclick()}
              >
                <span>{t("loadMoreText")}</span>
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Media;
