import React from "react";
import SwiperCore, {
  Navigation,
  EffectFade,
  Pagination,
  A11y,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Style from "./Testimonail.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import ReactHtmlParser from "react-html-parser";

const Testimonial = ({ testimonialsData }) => {
  const swiper = useSwiper();

  SwiperCore.use([Navigation, EffectFade, Pagination, Scrollbar, A11y]);

  return (
    <div className={`${Style.clientestimonials} ${Style.loaded}`}>
      <div className="container">
        <header className={`${Style.reviewtitle}`}>
          <figure className={Style.reviewicon}>
            <img
              src={
                testimonialsData?.details?.section_details?.[
                  "home-testimonials-logo"
                ]?.url
              }
              alt="logo"
            />
          </figure>
          <h2>
            {
              testimonialsData?.details?.section_details?.[
                "home-testimonials-main-title-small"
              ]
            }
          </h2>
        </header>
        {testimonialsData?.details?.list && (
          <Swiper
            className={Style.reviewslider}
            effect="fade"
            pagination={{
              clickable: true,
            }}
            autoHeight={true}
            breakpoints={{
              768: {
                pagination: false,
                navigation: {
                  nextEl: ".swipe-next",
                  prevEl: ".swipe-prev",
                },
              },
            }}
          >
            {testimonialsData &&
              testimonialsData?.details?.list?.map((value, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className={Style.slideitem}>
                      <div className={Style.cnt}>
                        <p>{ReactHtmlParser(value?.post_content)}</p>
                      </div>
                      <div className={Style.author}>
                        <figure className={Style.avatar}>
                          <img src={value?.post_image} alt="testimonial" />
                        </figure>
                        <div className={Style.authname}>
                          <header>
                            {value?.post_title}
                            <span className={Style.loc}>{value?.country}</span>
                          </header>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}

            <div
              className={`swipe-prev ${Style.swiperarw} ${Style.swiper_prev}`}
            >
              <IcoMoon icon="swiper-prev" size={19} />
            </div>
            <div
              className={`swipe-next ${Style.swiperarw} ${Style.swiper_nxt}`}
            >
              <IcoMoon
                icon="swiper-next"
                size={19}
                onClick={() => swiper?.slideNext()}
              />
            </div>
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Testimonial;
