import React from "react";
import Style from "./ThankyouComponent.module.scss";
import Assets from "../Layouts/Assets";
import { Link } from "react-router-dom";

const ThankyouComponent = () => {
  return (
    <div className="container">
      <div className={Style.thankwrap}>
        <div>
          <figure>
            <img src={Assets.thanks_img} alt="thanks image" />
          </figure>
          <div className={Style.thanktitle}>
            <h2>Thank you!</h2>
          </div>
          <div className={Style.thankmsg}>
            <p>for initiating your journey with Sharaf Exchange</p>
          </div>
          {/* <div className={Style.visitext}>
            <p>
              Please visit the <Link to="/our-branches">nearest branch</Link> of Sharaf Exchange to
              finish your KYC”
            </p>
          </div> */}
          <Link className={`btn btn-brdr w-auto ${Style.donebtn}`} to={"/"}>
            <span>Home</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankyouComponent;
