import React from "react";
import Helmet from "react-helmet";
import { useHeader } from "../../logic/useHeader";
import { useSelector } from "react-redux";

const TitleComponent = ({ title, description,schema }) => {
  var defaultTitle =
    "Top Currency Exchange Company in Dubai | Sharaf Exchange ";
    const { menuData } = useHeader();
    const { footerData } = useSelector((state) => state.footer);
    
    
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <Helmet>
       <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <title>{title ? title : defaultTitle}</title>
      <meta
        name="description"
        content={description ? description : defaultTitle}
      />
      {/* Open Graph(OG) Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultTitle} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:image" content={menuData?.og_image?.url} />

      {/* Twitter card meta tags */}

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultTitle} />
      <meta name="twitter:image" content={menuData?.og_image?.url} />
      <meta
        name="twitter:site"
        content={footerData?.details?.menus?.social_media[1].url}
      />
      <meta name="twitter:url" content={currentUrl} />

      {/* Canonical Tags */}

      <link rel="canonical" href={currentUrl} />
    </Helmet>
  );
};

export { TitleComponent };
