import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import Style from "./HomeBannerSlider.module.scss";
import { Link } from "react-router-dom";
import Icomoon from "../Layouts/IcoMooon";
import { useDimensions } from "../../logic/Dimensions";
import ReactHtmlParser from "react-html-parser";
import { useCurrencyGraph } from "../../logic/useCurrencyGraph";

const HomeBannerSlider = ({ bannerData, serviceData, currencyRatesData }) => {
  const windowDimension = useDimensions();
  const bannerSliderRef = useRef();
  const progressRef = useRef();

  const { graphData, graphOptions, graphDays } = useCurrencyGraph();

  const pauseVideo = (swiper) => {
    const videofiles = swiper.el.querySelectorAll("video");
    videofiles.forEach((element) => {
      element.pause();
      element.currentTime = 0;
    });
  };

  const handleStart = (swiper) => {
    const videofile = swiper?.visibleSlides[0]?.querySelector("video");
    pauseVideo(swiper);
    if (videofile) {
      swiper.autoplay.stop();
    } else {
      swiper.autoplay.start();
    }
  };

  const handleEnd = (swiper) => {
    const videofile = swiper?.visibleSlides[0]?.querySelector("video");
    pauseVideo(swiper);
    if (videofile) {
      videofile.play();
      videofile.addEventListener("ended", () => {
        videofile.currentTime = 0;
        swiper?.slideNext();
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className={`${Style.home_banner_slider}
      ${
        serviceData &&
        bannerData &&
        currencyRatesData &&
        graphData &&
        graphOptions &&
        graphDays
          ? Style.banner_visible
          : ""
      }`}
    >
      <Swiper
        slidesPerView={1}
        modules={[EffectFade, Autoplay, Pagination]}
        effect="fade"
        speed={400}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        // allowTouchMove={false}
        pagination={{
          el: ".custom_fraction",
          type: "fraction",
        }}
        loop
        ref={bannerSliderRef}
        // onInit={(swiper) => {
        //   handleSlideChange(swiper);
        // }}
        onSlideNextTransitionStart={(swiper) => {
          handleStart(swiper);
        }}
        onSlideChangeTransitionStart={() => {
          if (progressRef.current?.classList.contains("fill-anim")) {
            progressRef.current?.classList.remove("fill-anim");
          }
          if (progressRef.current?.classList.contains("initial-fill-anim")) {
            progressRef.current?.classList.remove("initial-fill-anim");
          }
        }}
        onSlideChangeTransitionEnd={(swiper) => {
          progressRef.current?.classList.add("fill-anim");
          handleEnd(swiper);
        }}
      >
        {bannerData?.details?.map((data, index) => {
          return (
            <SwiperSlide
              data-swiper-autoplay="4000"
              className={Style.home_banner_slider_slide}
              key={data.id}
            >
              {({ isActive }) => (
                <>
                  {data?.media?.type === "image" ? (
                    <figure className={Style.home_banner_slider_image}>
                      <img
                        src={
                          windowDimension.width >= 768
                            ? data?.media?.url
                            : data?.mobile_media?.url
                        }
                        alt="Banner"
                      />
                    </figure>
                  ) : (
                    <div className={Style.home_banner_slider_video}>
                      {/* <video
                    src={
                      windowDimension.width >= 768
                        ? data?.media?.url
                        : data?.media?.video_mobile_url
                    }
                    autoPlay
                    muted
                    loop
                    playsInline
                  ></video> */}
                      <VideoPlayer data={data} isActive={isActive} />
                    </div>
                  )}
                  <div className={Style.home_banner_slider_contents}>
                    <div className={Style.slide_content_wrap}>
                      <div
                        className={Style.banner_overlay}
                        style={{
                          backgroundColor:
                            data?.media?.type === "video"
                              ? data?.["overlay-color"]
                              : "",
                        }}
                      />
                      <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className={Style.banner_left}>
                            {data?.title && (
                              <div
                                className={`${Style.home_banner_slider_title} anim-title`}
                              >
                                {ReactHtmlParser(data?.title)}
                              </div>
                            )}
                          </div>
                          {windowDimension.width >= 1200 &&
                            (data?.contents.type === "plane-content" ? (
                              <div className={Style.banner_right}>
                                <article className={Style.plane_content}>
                                  <h4 className={Style.plane_content_title}>
                                    {data?.contents?.title}
                                  </h4>
                                  <p
                                    className={Style.plane_content_description}
                                  >
                                    {data?.contents?.description}
                                  </p>

                                  {data?.contents?.button?.url?.indexOf(
                                    "://"
                                  ) == -1 ? (
                                    <Link
                                      to={`${data?.contents?.button?.url}`}
                                      className={`vissible}  btn white ${Style.openingbtn}`}
                                    >
                                      <span>
                                        {data?.contents?.button?.text}{" "}
                                      </span>
                                    </Link>
                                  ) : (
                                    <a
                                      href={data?.contents?.button?.url}
                                      className={`vissible}  btn white ${Style.openingbtn}`}
                                      target="_blank"
                                    >
                                      <span>
                                        {data?.contents?.button?.text}{" "}
                                      </span>
                                    </a>
                                  )}
                                </article>
                              </div>
                            ) : data?.contents?.type === "card-content" ? (
                              <div
                                className={`${Style.banner_right} d-flex align-items-center`}
                              >
                                <div className={Style.card_content}>
                                  <figure className={Style.card_content_image}>
                                    <img
                                      src={data?.contents?.image}
                                      alt="Banner"
                                    />
                                  </figure>
                                  <div className={Style.card_content_contents}>
                                    <div className={Style.card_content_wrap}>
                                      <h4 className={Style.card_content_title}>
                                        {data?.contents.title}
                                      </h4>

                                      {data?.contents?.button?.url?.indexOf(
                                        "://"
                                      ) == -1 ? (
                                        <Link
                                          to={`${data?.contents?.button?.url}`}
                                          className="arw-link"
                                        >
                                          <span>
                                            <Icomoon
                                              icon="arrow-right"
                                              size={12}
                                            />
                                          </span>
                                          {data?.contents?.button?.text}
                                        </Link>
                                      ) : (
                                        <a
                                          href={data?.contents?.button?.url}
                                          className="arw-link"
                                          target="_blank"
                                        >
                                          <span>
                                            {data?.contents?.button?.text}
                                          </span>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              data?.contents.type === "" && ""
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <div className={Style.home_banner_slider_progress_wrap}>
        <div className="container">
          <div className={Style.home_banner_slider_progress}>
            <div className="custom_fraction"></div>
            <div className={Style.progress_bar}>
              <div
                className={`${Style.progress_fill} initial-fill-anim`}
                ref={progressRef}
              ></div>
            </div>
          </div>
        </div>
      </div> */}
      <h1 className="d-none p-0 m-0">Top Currency Exchange Company in Dubai</h1>
    </section>
  );
};

export default HomeBannerSlider;

const VideoPlayer = ({ data, isActive }) => {
  const videoRef = useRef(null);
  const windowDimension = useDimensions();

  // useEffect(() => {
  //   if (isActive) {
  //     videoRef.current.play();
  //   } else {
  //     videoRef.current.pause();
  //   }
  // }, [isActive]);

  return (
    <>
      {/* <video ref={videoRef} width="600" controls>
              <source src="your-video-file.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <button onClick={togglePlayPause}>
              {isPlaying ? 'Pause' : 'Play'}
          </button> */}
      <video
        ref={videoRef}
        src={
          windowDimension.width >= 768
            ? data?.media?.url
            : data?.media?.video_mobile_url
        }
        autoPlay
        // controls
        muted
        // loop
        playsInline
      ></video>
    </>
  );
};
