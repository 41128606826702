import React, { useEffect } from "react";
import Style from "../../HomeServicesTab.module.scss";
import Accordion from "react-bootstrap/Accordion";
import Icomoon from "../../../Layouts/IcoMooon";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const ServiceAccordion = ({ serviceData, valueImage }) => {
  let location = useLocation();

  const { commonSlug } = useParams();

  useEffect(() => {
    const accoidion_item = document.querySelectorAll(".accordion-button");
    accoidion_item.forEach((element, index) => {
      element.addEventListener("click", () => {
        element.parentNode.parentNode.classList.toggle("active");
      });
    });
  });

  return (
    <>
      <Accordion
        defaultActiveKey={0}
        className={`accordionview ${Style.service_acc}`}
      >
        {serviceData &&
          serviceData?.length > 0 &&
          serviceData?.map((value, i) => {
            return value.title || value?.["service-name"] ? (
              <Accordion.Item eventKey={i} className={Style.acc_item}>
                <Accordion.Header className={Style.acc_head}>
                  {location.pathname == "/services/value-added-services" ||
                  window.location.pathname == "/services/" + commonSlug
                    ? value?.["service-name"]
                    : value?.["our-services-list"]}
                </Accordion.Header>
                <Accordion.Body className={Style.acc_content}>
                  {(valueImage &&
                    location.pathname == "/services/value-added-services") ||
                  window.location.pathname == "/services/" + commonSlug ? (
                    <div className={Style.figwrap}>
                      <figure className={Style.item_img}>
                        <img src={value?.["service-featured-image"].url} alt="service" />
                      </figure>
                    </div>
                  ) : (
                    ""
                  )}
                  <header>
                    {" "}
                    {location.pathname === "/services/value-added-services" ||
                    window.location.pathname == "/services/" + commonSlug
                      ? value?.["service-featured-title"]
                      : value?.title}
                  </header>
                  <p>
                    {location.pathname === "/services/value-added-services" ||
                    window.location.pathname == "/services/" + commonSlug
                      ? value?.["service-featured-title"]
                      : value?.description}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            );
          })}
      </Accordion>
    </>
  );
};

export default ServiceAccordion;
