import React from "react";
import Style from "./EnquireDetails.module.scss";
import SocialMedias from "../../SocialMedias";
import { useTranslation } from "react-i18next";

const EnquireDetails = ({ enquiryData }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={Style.equirydetail}>
      <ul>
        <li>
          <span>{t("telephoneText")}</span>
          {/* <a
            href={`tel:${enquiryData?.details?.["page-more-details"]?.["enquiry-page-details"]?.["banner-toll-free-number"]}`}
          >
            {
              enquiryData?.details?.["page-more-details"]?.[
                "enquiry-page-details"
              ]?.["banner-toll-free-number"]
            }
          </a> */}
          <div className={Style.equirydetail_ph}>
            <p>
              {
                enquiryData?.details?.["page-more-details"]?.[
                  "enquiry-page-details"
                ]?.["phone_number_uae"]?.["phone_uae_text"]
              }
              :&nbsp;&nbsp;
              <a
                href={`tel:${enquiryData?.details?.["page-more-details"]?.["enquiry-page-details"]?.["phone_number_uae"]?.["phone_uae"]}`}
              >
                {
                  enquiryData?.details?.["page-more-details"]?.[
                    "enquiry-page-details"
                  ]?.["phone_number_uae"]?.["phone_uae"]
                }
              </a>
            </p>
          </div>
          <div className={Style.equirydetail_ph}>
            <p>
              {
                enquiryData?.details?.["page-more-details"]?.[
                  "enquiry-page-details"
                ]?.["phone_number_outside_uae"]?.["phone_outside_uae_text"]
              }
              :&nbsp;&nbsp;
              <a
                href={`tel:${enquiryData?.details?.["page-more-details"]?.["enquiry-page-details"]?.["phone_number_outside_uae"]?.["phone_outside_uae"]}`}
              >
                {
                  enquiryData?.details?.["page-more-details"]?.[
                    "enquiry-page-details"
                  ]?.["phone_number_outside_uae"]?.["phone_outside_uae"]
                }
              </a>
            </p>
          </div>
        </li>
        <li>
          <span>{t("emailText")}</span>
          <a
            href={`mailto:${enquiryData?.details?.["page-more-details"]?.["enquiry-page-details"]?.["banner-email"]}`}
          >
            {
              enquiryData?.details?.["page-more-details"]?.[
                "enquiry-page-details"
              ]?.["banner-email"]
            }
          </a>
        </li>
        <li>
          <span>{t("connectText")}</span>
          <SocialMedias />
        </li>
      </ul>
    </div>
  );
};

export default EnquireDetails;
