import React, { useState } from "react";
import Style from "./LoginSignup.module.scss";
import ModalForm from "../../../ModalForm";
import ModalThanks from "../../../ModalThanks";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
const LoginSignup = () => {
  const [modalShow, setModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const { footerData } = useSelector((state) => state.footer);

  let cookieContent = footerData?.details?.footer_more_details;

  return (
    <div className={Style.logins}>
      <Button
        onClick={() => {
          setModalShow(true);
          setSuccessMessage(false);
        }}
        className={`btn btn-primary ${Style.loginbtn}`}
      >
        <span>{cookieContent?.become_customer_title}</span>
      </Button>
      <Modal
        size="lg"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        aria-labelledby="applynow-modal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {successMessage ? (
            <ModalThanks setModalShow={setModalShow} />
          ) : (
            <ModalForm
              setModalShow={setModalShow}
              modalShow={modalShow}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoginSignup;
