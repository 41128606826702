import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Style from "./Cookies.module.scss";
import ReactHtmlParser from "react-html-parser";

const Cookies = ({ handleClickAccept, setIsAccept, isAccept }) => {
  const { footerData } = useSelector((state) => state.footer);

  let cookieContent = footerData?.details?.footer_more_details;

  let isArabic = localStorage.getItem("lang") === "ar";

  return (
    <div>
      <div className={Style.cookies_wrapper}>
        <h2 className={Style.cookies_content}>
          {ReactHtmlParser(cookieContent?.accept_cookies_content)}
        </h2>
        <button
          className={`btn btn-primary ${Style.cookies_accept_button}`}
          onClick={() => handleClickAccept()}
        >
          {!isArabic ? "Accept cookies" : "قبول ملفات تعريف الارتباط"}
        </button>
        <button className={Style.cookies_close} onClick={() => handleClickAccept()}>
          X
        </button>
      </div>
    </div>
  );
};

export default Cookies;
