import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  serviceData: null,
  loaderClass: false,

};

export const getService = createAsyncThunk(
  "service/getService",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/our-services`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);


const homeOurServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {},
  extraReducers: {
    [getService.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.loaderClass = false;

    },
    [getService.fulfilled]: (state, action) => {
      state.status = "succeeded";
      
      state.serviceData = action.payload?.data;
      state.loaderClass = true;
    },
    [getService.rejected]: (state, action) => {
      state.status = "failed";
      state.loaderClass = false;

    },
  },
});

export default homeOurServiceSlice.reducer;
