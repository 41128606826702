import { values } from "lodash";
import { useSelector } from "react-redux";
import IcoMoon from "../Layouts/IcoMooon";

const SocialMedias = () => {
  const { footerData } = useSelector((state) => state.footer);
  
  return (
    <>
      {}
      {footerData && (
        <ul>
          {footerData?.details?.menus?.social_media?.map((value, i) => {
            return (
              <li>
                <a href={value?.url} target="_blank" rel="noreferrer" key={i}>
                  <IcoMoon icon={value?.["icon-svg-code"]} size={value?.size} />
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default SocialMedias;
