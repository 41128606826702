import React, { useEffect, useRef } from "react";
import Style from "./RelatedTopics.module.scss";
import Assets from "../Layouts/Assets";
import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const RelatedTopics = ({ single, catID }) => {
  const {t} = useTranslation(['common'])
  let relatedData = single?.details?.related_posts;

  const relatedshape = useRef();
  useEffect(() => {
    gsap.to(relatedshape.current, {
      x: 200,
      y: "-40%",
      duration: 0.5,
      delay: 0.4,
      opacity: 0.3,
      scrollTrigger: {
        scrub: 2,
        trigger: relatedshape.current,
      },
    });
  });
  return (
    <div className={Style.realtedtopics}>
      <span className={Style.circleshape} ref={relatedshape}></span>
      <div className="container">
        <div className={Style.smcontainer}>
          <div className={`text-md-center ${Style.titlesec}`}>
            <h2>{t("RelatedTopicText")}</h2>
          </div>
          <div className={Style.postlistwrap}>
            {relatedData &&
              relatedData?.length > 0 &&
              relatedData?.map((value, i) => {
                return (
                  <div key={i}>
                    <Link
                      to={`/media-detail/${value?.categories_ids}/${value?.ID}`}
                      className={Style.newitem}
                    >
                      {value?.post_thumbnail_url && (
                        <div className={Style.figwrap}>
                          <figure className={Style.postimg}>
                            <img src={value?.post_thumbnail_url} alt="thumbnail" />
                          </figure>
                        </div>
                      )}
                      <div className={Style.details}>
                        <header className={Style.postitle}>{value?.post_title}</header>

                        {value?.post_thumbnail_url
                          ? ""
                          : value?.post_content && (
                              <div className={Style.cntsec}>{value?.post_content}</div>
                            )}

                        <div className={Style.postdate}>{value?.post_date_beautiful}</div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
          <div className={Style.btnwrap}>
            <Link to={`/media?${catID}`} className={`btn btn-brdr ${Style.viewmore}`}>
              <span>{t("ViewMoreText")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedTopics;
