import React from "react";
import { Accordion } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Style from "../../Footer.module.scss";
import { useTranslation } from "react-i18next";

const FooterMobileMenu = () => {
  const { footerData } = useSelector((state) => state.footer);
  const { t } = useTranslation(["common"]);
  let location = useLocation();
  let footerData1 = footerData?.details?.menus?.footer_menu_services_new?.map(
    (item, index) => {
      let data = { ...item, url: `/services${item.url}` };
      return data;
    }
  );
  return (
    <Accordion className={Style.menudropdown}>
      <Accordion.Item eventKey="0" className={Style.menu_item}>
        <Accordion.Header className={Style.menu_head}>Company</Accordion.Header>
        <Accordion.Body className={Style.menu_list}>
          <ul>
            {footerData?.details?.menus?.footer_menu_company_part1 &&
              footerData?.details?.menus?.footer_menu_company_part1?.length >
                0 &&
              footerData?.details?.menus?.footer_menu_company_part1?.map(
                (value, i) => {
                  let activeMedia =
                    location.pathname.split("/")[1] == "media-detail" &&
                    value?.url == "/media";

                  let activeCarrer =
                    location.pathname.split("/")[1] == "careers-detail" &&
                    value?.url == "/careers";
                  let activeMenu = location.pathname == value?.url;
                  return (
                    <li
                      key={i}
                      className={
                        activeMenu
                          ? Style.active
                          : activeMedia
                          ? Style.active
                          : activeCarrer
                          ? Style.active
                          : ""
                      }
                    >
                      <Link to={value?.url ? value?.url : "/"}>
                        {value?.title}
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
          <ul>
            {footerData?.details?.menus.footer_menu_company_part2_new &&
              footerData?.details?.menus?.footer_menu_company_part2_new
                ?.length > 0 &&
              footerData?.details?.menus?.footer_menu_company_part2_new?.map(
                (value, i) => {
                  let activeMedia =
                    location.pathname.split("/")[1] == "media-detail" &&
                    value?.url == "/media";

                  let activeCarrer =
                    location.pathname.split("/")[1] == "careers-detail" &&
                    value?.url == "/careers";
                  let activeMenu2 = location.pathname == value?.url;
                  return (
                    <li
                      key={i}
                      className={
                        activeMenu2
                          ? Style.active
                          : activeMedia
                          ? Style.active
                          : activeCarrer
                          ? Style.active
                          : ""
                      }
                    >
                      <Link to={value?.url ? value?.url : "/"}>
                        {value?.title}
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className={Style.menu_item}>
        <Accordion.Header className={Style.menu_head}>
          {t("servicesText")}
        </Accordion.Header>
        <Accordion.Body className={Style.menu_list}>
          <ul>
            {footerData?.details?.menus.footer_menu_services_new &&
              footerData?.details?.menus?.footer_menu_services_new?.length >
                0 &&
              footerData1?.map((value, i) => {
                let activeMenu1 = location.pathname == value?.url;
                return (
                  <li key={i} className={activeMenu1 ? Style.active : ""}>
                    <Link to={value?.url ? value?.url : "/"}>
                      {value?.title}
                    </Link>
                    {value?.class?.length > 0 && <span>{value?.class}</span>}
                  </li>
                );
              })}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FooterMobileMenu;
