import React, { useRef, useState } from "react";
import Style from "./ApplyNowForm.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import { Dropdown, DropdownButton } from "react-bootstrap";
import * as Yup from "yup";
import { useForm } from "../../logic/useForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { applyNowForm } from "../../store/slices/formSlice";
import GoogleRecaptcha from "../Recaptcha";
import { Spinner } from "../Spinner";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ApplyNowForm = ({ applyNowRef }) => {
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common"]);
  const ref = useRef();

  const { formKey } = useForm();

  const location = useLocation();

  const [captchaReset, setCaptchaReset] = useState(false);

  const [captchaValid, isCaptchaValid] = useState(false);
  const [experience, setExperience] = useState("Years Of Experience*");

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const FILE_SIZE = 1000 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "application/pdf",
    "image/png",
  ];

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    education: Yup.string().required("Education is required"),
    years_of_experience: Yup.string().required("Experience is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    apply_mobile_number: Yup.string()
      .matches(phoneRegExp, "Invalid mobile number")
      .required("Mobile number required")
      .min(8, "Minimum 8 digits is required "),
    googleCaptcha: Yup.string().required("reCaptcha is required"),
    files: Yup.mixed()
      .required("Resume is required")
      .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
        return value ? (value.size <= FILE_SIZE ? true : false) : true;
      })
      .test("FILE_Type", "Not valid!", (value) => {
        return value
          ? SUPPORTED_FORMATS.includes(value.type)
            ? true
            : false
          : true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      apply_mobile_number: "",
      years_of_experience: "",
      education: "",
      locSlug: location?.pathname,
      googleCaptcha: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
      files: "",
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        ref.current.value = "";

        setExperience("Years Of Experience");
        let newData = {
          ...data,
          files: selectedFile,
        };
        applyNowSubmit(newData);
        setSelectedFile(null);
        setCaptchaReset(true);
        setLoading(true);
      }
    },
  });

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const dispatch = useDispatch();

  const applyNowSubmit = async (data) => {
    var payload = new FormData();
    payload.append("full_name", data.full_name);
    payload.append("email_address", data.email);
    payload.append("mobile_number", data.apply_mobile_number);
    payload.append("years_of_experience", data.years_of_experience);
    payload.append("education", data.education);
    payload.append("upload_your_resume", selectedFile);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(applyNowForm(payload));

    if (applyNowForm.fulfilled.match(resultAction)) {
      setMessage(resultAction?.payload?.data?.message);
      setErrorMsg(resultAction?.payload?.data?.data?.[0]?.message);

      setLoading(false);
      setCaptchaReset(false);
      setTimeout(() => {
        setMessage(false);
        setErrorMsg(false);
      }, 2500);
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={Style.fieldwrapper} ref={applyNowRef}>
        <div>
          <div
            className={`input-holder  ${formik.values.full_name && `active`} ${
              formik.errors.full_name && formik.touched.full_name ? `error` : ""
            }`}
          >
            <input
              placeholder={t("fullNameText")}
              type="text"
              name="full_name"
              id="full_name"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.full_name}
              onBlur={() => {
                formik.handleBlur();
              }}
            />
            <label htmlFor="full_name" className="forvalue">
              {t("fullNameText")}
            </label>

            {formik.touched.full_name && formik.errors.full_name ? (
              <div className="text-danger">{formik.errors.full_name}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            className={`input-holder ${formik.values.email && `active`} ${
              formik.errors.email && formik.touched.email ? `error` : ""
            } `}
          >
            <input
              placeholder={t("emailAddressText")}
              type="text"
              name="email"
              id="email"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={() => {
                formik.handleBlur();
              }}
            />
            <label htmlFor="email" className="forvalue">
              {t("emailAddressText")}
            </label>
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            className={`input-holder ${
              formik.values.apply_mobile_number && `active`
            } ${
              formik.errors.apply_mobile_number &&
              formik.touched.apply_mobile_number
                ? `error`
                : ""
            } `}
          >
            <input
              placeholder={t("mobileNumberText")}
              type="text"
              name="apply_mobile_number"
              id="apply_mobile_number"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.apply_mobile_number}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onBlur={() => {
                formik.handleBlur();
              }}
              maxLength={15}
            />
            <label htmlFor="apply_mobile_number" className="forvalue">
              {t("mobileNumberText")}
            </label>
            {formik.touched.apply_mobile_number &&
            formik.errors.apply_mobile_number ? (
              <div className="text-danger">
                {formik.errors.apply_mobile_number}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            className={`input-holder ${
              formik.values.years_of_experience && `active`
            } ${
              formik.errors.years_of_experience &&
              formik.touched.years_of_experience
                ? `error`
                : ""
            }`}
          >
            <DropdownButton
              className="form-select"
              title={experience}
              id="years_of_experience"
              name="years_of_experience"
              onSelect={(e) => {
                setExperience(e);
                formik.setFieldValue("years_of_experience", e);
              }}
              onChange={formik.handleChange}
              value={formik.values.years_of_experience}
              onBlur={() => {
                formik.handleBlur();
              }}
            >
              <Dropdown.Item eventKey="1" id="1">
                1
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" id="2">
                2
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" id="3">
                3
              </Dropdown.Item>
              <Dropdown.Item eventKey="4" id="4">
                4
              </Dropdown.Item>
            </DropdownButton>
            {formik.touched.years_of_experience &&
            formik.errors.years_of_experience ? (
              <div className="text-danger">
                {formik.errors.years_of_experience}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            className={`input-holder ${formik.values.education && `active`} ${
              formik.errors.education && formik.touched.education ? `error` : ""
            }`}
          >
            <input
              placeholder="Education*"
              type="text"
              name="education"
              id="education"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.education}
              onBlur={() => {
                formik.handleBlur();
              }}
              maxLength={10}
            />
            <label htmlFor="education" className="forvalue">
              Education*
            </label>
            {formik.touched.education && formik.errors.education ? (
              <div className="text-danger">{formik.errors.education}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div
            className={`input-holder ${formik.values.files && `active`} ${
              formik.errors.files && formik.touched.files ? `error` : ""
            }`}
          >
            <div className="uploadwraper">
              <div className="uploadlabel">
                <label htmlFor="file_upload" className="fileupload">
                  <IcoMoon icon="attach" size={15} />
                  <span>
                    {selectedFile && selectedFile?.name
                      ? selectedFile?.name
                      : t("uploadResumeText")}
                  </span>
                </label>
                {formik.touched.files && formik.errors.files ? (
                  <div className="text-danger">{formik.errors.files}</div>
                ) : null}
              </div>
              <div className="filetypes">
                <p>File Type: (PDF, DOC, DOCX, JPEG, PNG) Under 7MB.</p>
              </div>
            </div>
            <input
              ref={ref}
              type="file"
              name="files"
              accept="application/pdf,image/jpg,
                  image/jpeg,
                  image/png"
              id="file_upload"
              className="file-control"
              onChange={(event) => {
                handleFileSelect(event);
                formik.setFieldValue("files", event.currentTarget.files[0]);
              }}
            />
          </div>
        </div>
        <div>
          <div
            className={`input-holder captcha-holder ${
              formik.values.googleCaptcha && `active`
            } ${
              formik.errors.googleCaptcha && formik.touched.googleCaptcha
                ? `error`
                : ""
            }`}
          >
            <GoogleRecaptcha
              captchaReset={captchaReset}
              name="googleCaptcha"
              value={formik.values.googleCaptcha}
              onBlur={formik.handleBlur}
              onChange={(value) => {
                changeCaptcha(value);
                formik.setFieldValue("googleCaptcha", value);
              }}
            />
            {formik.touched.googleCaptcha && formik.errors.googleCaptcha ? (
              <div className="text-danger">{formik.errors.googleCaptcha}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div className="input-holder btn-holder">
            {!loading ? (
              <input
                type="submit"
                className={`btn btn-primary ${Style.submitbtn}`}
                value="Submit"
              />
            ) : (
              <>
                <button
                  type="submit"
                  className={`btn btn-primary ${Style.submitbtn}`}
                >
                  <Spinner />
                </button>
              </>
            )}
          </div>
        </div>

        {message && (
          <div className={`${Style.success_messsage} success_messsage ml-auto`}>
            <div>
              {" "}
              Thank you for submission, our team will get back to you shortly
            </div>
          </div>
        )}
        {errorMsg && (
          <div className={`${Style.success_messsage} success_messsage ml-auto`}>
            <div>
              Number of submission restrcited.Please try again after 5 minutes
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ApplyNowForm;
