import React from "react";
import { Link } from "react-router-dom";

import Style from "./RatesDesktopView.module.scss";
import { useTranslation } from "react-i18next";

const RatesDesktopView = ({ searchData }) => {
  const { t } = useTranslation(["common"]);
  const isArabic = localStorage.getItem("lang") === "ar";
  // const convertToArabicNumerals = (num) => {
  //   const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  //   return num.toString().replace(/[0-9]/g, (digit) => arabicNumbers[digit]);
  // };
  return (
    <div className="container">
      <div className={Style.tablesec}>
        <div className={`d-none d-md-block ${Style.table}`}>
          <div className={Style.tablehead}>
            <div className={Style.index}>#</div>
            <div className={Style.currency}>{t("currencyTableHead1")}</div>
            <div className={Style.dd_dt}>{t("currencyTableHead2")}</div>
            <div className={Style.fc_buy}>{t("currencyTableHead3")}</div>
            <div className={Style.fc_cell}>{t("currencyTableHead4")}</div>
          </div>
          <div className={Style.bodynote}>
            <ul className={Style.tablebody}>
              {searchData && searchData?.length > 0 ? (
                searchData?.map((value, i) => {
                  return (
                    <li>
                      <div className={Style.index} key={i}>
                        {/* {isArabic ? convertToArabicNumerals(i + 1) : i + 1}  Convert to Arabic if needed */}
                        {i + 1}
                      </div>
                      <div className={Style.currency}>
                        <span className={Style.icon}>
                          <img src={value?.country_flag} alt="country" />
                        </span>
                        {isArabic ? value?.["title-ar"] : value?.["title-en"]}
                      </div>
                      <div className={Style.dd_dt}>{value?.dd_tt}</div>
                      <div className={Style.fc_buy}>{value?.fc_buy}</div>
                      <div className={Style.fc_cell}>{value?.fc_sell}</div>
                    </li>
                  );
                })
              ) : (
                <div className={Style.no_result}>
                  <span>{t("noResultText")}</span>
                </div>
              )}
            </ul>
            <div className={Style.tablenote}>
              <p>
                {t("exchangeRateSubtitle")}. {t("contactOurText")}{" "}
                <Link to={"/our-branches"}>{t("nearestBranchText")}&nbsp;</Link>
                {t("latestRateText")}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RatesDesktopView;
