import { useEffect } from "react";
import { getService } from "../store/slices/homeOurServiceSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeOurService = () => {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.service);

  
  useEffect(() => {
    if (serviceData.status === "idle") {
      dispatch(getService());
    }
  },[]);

 

  return {
    serviceData: serviceData?.serviceData,
  };
};
