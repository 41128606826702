import React, { useEffect, useState } from "react";
import Style from "./ModalForm.module.scss";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useForm } from "../../logic/useForm";
import { customerForm } from "../../store/slices/formSlice";
import GoogleRecaptcha from "../Recaptcha";
import { Spinner } from "../Spinner";

const ModalForm = ({ becomePop, setModalShow, successMessage, setSuccessMessage }) => {
  const { formKey } = useForm();
  const [captchaReset, setCaptchaReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const [captchaValid, isCaptchaValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const validationSchema = Yup.object().shape({
    modal_full_name: Yup.string().required("Name is required"),
    modal_email: Yup.string().required("Email is required").email("Email is invalid"),
    emirates_id: Yup.string()
      .required("Emirates Id is required")
      .min(15, "Minimum 15 digit is required "),
    googleCaptcha: Yup.string().required("reCaptcha is required"),
    modal_mobile_number: Yup.string()
      .matches(phoneRegExp, "*Invalid mobile number")
      .required("Mobile number required")
      .min(8, "Minimum 8 digit is required "),
  });

  const formik = useFormik({
    initialValues: {
      modal_full_name: "",
      modal_email: "",
      modal_mobile_number: "",
      emirates_id: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
      googleCaptcha: "",
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      if (captchaValid) {
        formik.resetForm();
        enquirySubmit(data);
        setCaptchaReset(true);
        setLoading(true);
      }
    },
  });
  const dispatch = useDispatch();

  const changeCaptcha = (value) => {
    if (value) {
      isCaptchaValid(true);
    }
  };

  const enquirySubmit = async (data) => {
    var payload = new URLSearchParams();
    payload.append("full_name", data.modal_full_name);
    payload.append("email_address", data.modal_email);
    payload.append("mobile_number", data.modal_mobile_number);
    payload.append("emirates-id", data.emirates_id);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(customerForm(payload));

    if (customerForm.fulfilled.match(resultAction)) {
      setErrorMsg(resultAction?.payload?.data?.data?.[0]?.message);
      setTimeout(() => {
        setErrorMsg(false);
      }, 2500);
      setSuccessMessage(resultAction?.payload?.data?.success);
      setLoading(false);
      successMessage(true);
      setModalShow(true);
      setCaptchaReset(false);
    }
  };

  const { footerData } = useSelector((state) => state.footer);

  let cookieContent = footerData?.details?.footer_more_details;

  return (
    <>
      <div className={Style.fotmheader}>
        <div className={`h4 ${Style.formtitle}`}>{cookieContent?.become_customer_title}</div>
        <p>{cookieContent?.become_customer_content}</p>
      </div>
      {/* <iframe width="410px" height="380px" src={`/zoho.html`}></iframe> */}
      <iframe
        // frameborder="0"
        src="https://forms.zohopublic.com/SharafExchange/form/BecomeaCustomer/formperma/_giiZpXE8BOLRCQEl87fxUr1JFDJYtmuihGGN4Vnxzo"
      ></iframe>

      {/* <div className={Style.formwrapper} id="modalForm">
        <form onSubmit={formik.handleSubmit}>
          <div className={Style.fieldwrapper}>
            <div
              className={`input-holder  ${formik.values.modal_full_name && `active`} ${
                formik.errors.modal_full_name && formik.touched.modal_full_name ? `error` : ""
              }`}
            >
              <input
                placeholder=" Full Name*"
                type="text"
                name="modal_full_name"
                id="modal_full_name"
                className="form-control"
                onChange={formik.handleChange}
                value={formik.values.modal_full_name}
                onBlur={() => {
                  formik.handleBlur();
                }}
              />

              <label htmlFor="modal_full_name" className="forvalue">
                Full Name*
              </label>
              {formik.touched.modal_full_name && formik.errors.modal_full_name ? (
                <div className="text-danger">{formik.errors.modal_full_name}</div>
              ) : null}
            </div>
            <div>
              <div
                className={`input-holder ${formik.values.modal_email && `active`} ${
                  formik.errors.modal_email && formik.touched.modal_email ? `error` : ""
                } `}
              >
                <input
                  placeholder="Email Address*"
                  type="text"
                  name="modal_email"
                  id="modal_email"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.modal_email}
                  onBlur={() => {
                    formik.handleBlur();
                  }}
                />
                <label htmlFor="modal_email" className="forvalue">
                  Email Address*
                </label>
                {formik.touched.modal_email && formik.errors.modal_email ? (
                  <div className="text-danger">{formik.errors.modal_email}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div
                className={`input-holder  ${formik.values.modal_mobile_number && `active`} ${
                  formik.errors.modal_mobile_number && formik.touched.modal_mobile_number
                    ? `error`
                    : ""
                }`}
              >
                <input
                  placeholder=" UAE Mobile Number*"
                  type="text"
                  name="modal_mobile_number"
                  id="modal_mobile_number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.modal_mobile_number}
                  onBlur={() => {
                    formik.handleBlur();
                  }}
                  maxLength={15}
                />
                <label htmlFor="modal_mobile_number" className="forvalue">
                  UAE Mobile Number*
                </label>
                {formik.touched.modal_mobile_number && formik.errors.modal_mobile_number ? (
                  <div className="text-danger">{formik.errors.modal_mobile_number}</div>
                ) : null}
              </div>
            </div>

            <div>
              <div
                className={`input-holder  ${formik.values.emirates_id && `active`} ${
                  formik.errors.emirates_id && formik.touched.emirates_id ? `error` : ""
                }`}
              >
                <input
                  placeholder=" Emirates ID*"
                  type="text"
                  name="emirates_id"
                  id="emirates_id"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.emirates_id}
                  onBlur={() => {
                    formik.handleBlur();
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={15}
                />
                <label htmlFor="emirates_id" className="forvalue">
                  Emirates ID*
                </label>
                {formik.touched.emirates_id && formik.errors.emirates_id ? (
                  <div className="text-danger">{formik.errors.emirates_id}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div
                className={`input-holder captcha-holder ${
                  formik.values.googleCaptcha && `active`
                } ${formik.errors.googleCaptcha && formik.touched.googleCaptcha ? `error` : ""}`}
              >
                <GoogleRecaptcha
                  captchaReset={captchaReset}
                  name="googleCaptcha"
                  value={formik.values.googleCaptcha}
                  onBlur={() => {
                    formik.handleBlur();
                  }}
                  onChange={(value) => {
                    changeCaptcha(value);
                    formik.setFieldValue("googleCaptcha", value);
                  }}
                />
                {formik.touched.googleCaptcha && formik.errors.googleCaptcha ? (
                  <div className="text-danger">{formik.errors.googleCaptcha}</div>
                ) : null}
              </div>
            </div>
            <div>
              <div className="input-holder">
                {!loading ? (
                  <input
                    type="submit"
                    className={`btn btn-primary ${Style.submitbtn}`}
                    value="Submit"
                  />
                ) : (
                  <>
                    <button type="submit" className={`btn btn-primary ${Style.submitbtn}`}>
                      <Spinner />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          {errorMsg && (
            <div className={`${Style.success_messsage} success_messsage ml-auto`}>
              <div>Number of submission restrcited.Please try again after 5 minutes</div>
            </div>
          )}
        </form>
      </div> */}
    </>
  );
};

export default ModalForm;
