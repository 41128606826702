import React, { useEffect, useRef } from "react";
import Style from "./JoinUs.module.scss";
import { Link } from "react-router-dom";
import Assets from "../Layouts/Assets";
import { useHomeCurrentOpening } from "../../logic/useHomeCurrentOpening";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";

gsap.registerPlugin(ScrollTrigger);
const JoinUs = () => {
  const { currentData } = useHomeCurrentOpening();
  /* useEffect(() => {
    gsap.to(`.${Style.shape}`, {
      rotate: 120,
      scrollTrigger: {
        trigger: `.${Style.wrapper}`,
        scrub: 1.2,
      },
    });
  }); */

  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div ref={ref1} className={`${Style.joinusec} vissible`}>
      <div className="container">
        <div className={Style.wrapper}>
          <div ref={ref1} className={`vissible} ${Style.smtitle}`}>
            {currentData?.details?.["home-career-banner-main-title-small"]}
          </div>
          <header className={`anim ${Style.secmainhead}`}>
            {currentData?.details?.["home-career-banner-main-title-large"]}
          </header>
          {currentData?.details?.["home-career-banner-main-button-url"]?.indexOf("://") == -1 ? (
            <Link
              to={`${currentData?.details?.["home-career-banner-main-button-url"]}`}
              className={`vissible}  btn white ${Style.openingbtn}`}
            >
              <span>{currentData?.details?.["home-career-banner-main-button-text"]}</span>
            </Link>
          ) : (
            <a
              href={currentData?.details?.["home-career-banner-main-button-url"]}
              className={`vissible}  btn white ${Style.openingbtn}`}
              target="_blank"
            >
              <span>{currentData?.details?.["home-career-banner-main-button-text"]}</span>
            </a>
          )}

          <span className={Style.shape}>
            <img src={currentData?.details?.["home-career-banner-logo"]?.url} alt="service" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
