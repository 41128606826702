import { useDynamicMenu } from "../../logic/useDynamicMenu";
import Style from "./CommonService.module.scss";
import ReactHtmlParser from "react-html-parser";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../store/slices/serviceSlice";
import { useEffect } from "react";
import ServiceFaq from "../ServiceFaq";
import ProgressLoader from "../ProgressLoader";
import { TitleComponent } from "../TitleComponent";
import ServiceBanner from "../ServiceBanner";
import CurrencyFold from "../CurrencyFold";
import ServiceExchangeRates from "../ServiceExchangeRates";
import ExchangeSendMoney from "../ExchangeSendMoney";
import ServiceFeatures from "../ServiceFeatures";

const CommonServiceInner = () => {
  let loc = window.location.pathname.split("/")[2];

  const dispatch = useDispatch();

  const pageService = useSelector((state) => state.pageService);

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, [loc]);


  
  return (
    <>
      {pageService?.[loc]?.details[0] ? "" : <ProgressLoader />}
      <TitleComponent
        title={pageService?.[loc]?.details[0]?.wpseo_title}
        description={pageService?.[loc]?.details[0]?.wpseo_metadesc}
      />

      {pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.status === "true" && (
        <ServiceBanner />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["graph-status"] === "true" && (
        <CurrencyFold />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["exchange-rate-status"] === "true" && (
        <ServiceExchangeRates />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.status ===
        "true" && <ExchangeSendMoney />}

      {pageService?.[loc]?.details[0]?.page_sections?.["service-features"]?.status === "true" && (
        <ServiceFeatures />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.faq?.status === "true" && <ServiceFaq />}
    </>
  );

  // return (
  //   <>
  //     {pageService?.[loc]?.details[0] ? "" : <ProgressLoader />}

  //     <div className={Style.postdetail}>
  //       <div className="container">
  //         <div className={`${Style.smcontainer}`}>
  //           <div className={Style.smwrapper}>
  //             <div className={Style.title_detail}>
  //               <div className={`smtitle ${Style.smtitle}`}>
  //                 {
  //                   pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.[
  //                     "banner-field-set-first-small-text"
  //                   ]
  //                 }
  //               </div>
  //               <h2>{pageService?.[loc]?.details[0]?.post_title}</h2>
  //             </div>
  //           </div>
  //           <div className={Style.postfigwrap}>
  //             <figure className={Style.imgwrap}>
  //               <img
  //                 src={
  //                   pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.[
  //                     "banner-field-set-image"
  //                   ]?.url
  //                 }
  //                 alt=""
  //               />
  //             </figure>
  //           </div>
  //           <div className={Style.smwrapper}>
  //             <div className={Style.postcnt}>
  //               {ReactHtmlParser(
  //                 pageService?.[loc]?.details[0] &&
  //                   pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.[
  //                     "banner-field-set-main-description"
  //                   ]
  //               )}
  //             </div>
  //             <div className={Style.postdetail_share}></div>
  //             <ServiceFaq />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default CommonServiceInner;
