import React from "react";
import { Link } from "react-router-dom";
import Style from "../../Footer.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CompanyMenu = () => {
  let location = useLocation();
  const { footerData } = useSelector((state) => state.footer);
  const { t } = useTranslation(["common"]);
  return (
    <>
      <div className="mobile-hide">
        {footerData && <div className={Style.titlesec}>{t("companyText")}</div>}
        <div className={Style.menuwrap}>
          <ul>
            {footerData?.details?.menus?.footer_menu_company_part1_new &&
              footerData?.details?.menus?.footer_menu_company_part1_new
                ?.length > 0 &&
              footerData?.details?.menus?.footer_menu_company_part1_new?.map(
                (value, i) => {
                  let activeMenu1 = location?.pathname == value?.url;
                  return (
                    <li key={i} className={activeMenu1 ? Style.active : ""}>
                      <Link to={value?.url ? value?.url : "/"}>
                        {value?.title}
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
          <ul>
            {footerData?.details?.menus?.footer_menu_company_part2_new &&
              footerData?.details?.menus?.footer_menu_company_part2_new
                ?.length > 0 &&
              footerData?.details?.menus?.footer_menu_company_part2_new?.map(
                (value, i) => {
                  let activeMedia =
                    location?.pathname.split("/")[1] == "media-detail" &&
                    value?.url == "/media";

                  let activeCarrer =
                    location?.pathname.split("/")[1] == "careers-detail" &&
                    value?.url == "/careers";
                  let activeMenu2 = location?.pathname == value?.url;
                  return (
                    <li
                      key={i}
                      className={
                        activeMenu2
                          ? Style.active
                          : activeMedia
                          ? Style.active
                          : activeCarrer
                          ? Style.active
                          : ""
                      }
                    >
                      <Link to={value?.url ? value?.url : "/"}>
                        {value?.title}
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CompanyMenu;
