import { useDynamicMenu } from "../../logic/useDynamicMenu";
import Style from "./DynamicMenu.module.scss";
import ReactHtmlParser from "react-html-parser";

const DynamicMenu = () => {
  const { dynamicData } = useDynamicMenu();

  return (
    <>
      <div className={`container ${Style.loaded}`}></div>

      <div className={Style.postdetail}>
        <div className="container">
          <div className={`${Style.smcontainer}`}>
            <div className={Style.smwrapper}>
              <div className={Style.title_detail}>
                <div className={`smtitle ${Style.smtitle}`}></div>
                <h2>
                  {dynamicData?.[0]?.post_title && dynamicData?.[0]?.post_title}
                </h2>
                <div className={Style.postdate}></div>
              </div>
            </div>
            <div className={Style.postfigwrap}>
              <figure className={Style.imgwrap}>
                <img
                  src={
                    dynamicData?.[0]?.post_thumbnail_url_in_webp &&
                    dynamicData?.[0]?.post_thumbnail_url_in_webp
                  }
                  alt="Post_Thumbnail"
                />
              </figure>
            </div>
            <div className={Style.smwrapper}>
              <div className={Style.postcnt}>
                <p>
                  {dynamicData?.[0]?.post_content &&
                    ReactHtmlParser(dynamicData?.[0]?.post_content)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicMenu;
